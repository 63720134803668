

.themes-container {
        height: fit-content;
        display: grid;
        grid-template-rows: repeat(6, 16.67%);
        grid-template-columns: repeat(1, 100%);
        overflow-y: hidden;
    }

.text-container-left {
        padding-top: 10px;
        padding-bottom: 50px;
        left: 0.4%;
        right: 0.4%;
        width: 65%;
        height: fit-content;
        position: relative;
    }
    
.text-container-right {
        padding-top: 10px;
        padding-bottom: 50px;
        left: 0.4%;
        right: 0.4%;
        width: 65%;
        height: fit-content;
        position: relative;
    }

.image-right {
        height: 100%;
        width: 35%;
        right: 0px;
        bottom: 0px;
        position: absolute;
        overflow: hidden;
        object-fit: cover;
    }
    
.image-left {
        height: 100%;
        width: 35%;
        right: 0px;
        bottom: 0px;
        position: absolute;
        overflow: hidden;
        object-fit: cover;
    }

.theme-title {
        font-size: 115%;
        font-weight: 700;
        color: rgb(1, 7, 74);
        display: 'inline-block';
    }
    
.theme-description {
        font-size: 100%;
        font-weight: 500;
        color: rgb(1, 7, 74);
        display: 'inline-block';
    }
.learn-more:hover { 
        filter: brightness(90%);
    }    


.dark-background-themes {
    padding-top: 25px;
    padding-bottom: 25px;
    padding-left: 20px;
    padding-right: 200px;
    background-color: rgb(229, 230, 231);
    position: relative;
}
.dark-background-themes:hover {
    background-color: rgb(229, 230, 231);
    filter: brightness(98%);
}

.light-background-themes {
    padding-top: 25px;
    padding-bottom: 25px;
    padding-left: 20px;
    padding-right: 200px;
    background-color: rgb(242, 242, 242);
    position: relative;
}
.light-background-themes:hover {
    background-color: rgb(242, 242, 242);
    filter: brightness(98%);
}


