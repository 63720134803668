.container {
    width: 100vw;
    overflow-y: hidden;
}

.dark {
    padding: 20px;
    background-color: rgb(229, 230, 231);
    position: relative;
}
.dark:hover {
    background-color: rgb(229, 230, 231);
    filter: brightness(98%);
}

.medium {
    padding: 20px;
    background-color: rgb(242, 242, 242);
    position: relative;
}
.medium:hover {
    background-color: rgb(242, 242, 242);
    filter: brightness(98%);
}

.news-div {
    left: 0;
    right: 0;
    width: 100%;
    height: fit-content;
    position: relative;
    padding-bottom: 8px;
}

.news-title {
      font-size: 115%;
      font-weight: 700;
      color: rgb(1, 7, 74);
      display: 'inline-block';
    }
  
.news-body {
      font-size: 105%;
      font-weight: 500;
      font-style: italic;
      color: rgb(1, 7, 74);
      display: 'inline-block';
    }
  
.date {
      font-size: 95%;
      font-weight: 500;
      color: rgb(1, 7, 74);
      display: 'inline-block';
    }

.link-button {
        background-color: transparent;
        border-radius: 10px;
        border-width: 1.5px;
        border-color: rgb(1, 7, 74);
        border-style: solid;
        width: fit-content;
        height: fit-content;
        margin-right: 1vw;
        margin-top: 10px;
    }

.button-div {
    left: 0;
    width: 100%;
    display: flex;
}

