
.body {
  max-width: 100vw;
  overflow: hidden;
}

@media screen and (min-width: 1651px) {
  .header {
    display: flex;
    border: none;
    height: 117.5px;
    position: relative;
    width: 100%;
    place-content: space-between;
    align-content: space-between;
    justify-content: space-between;
    right: 28px;
  }

  .ad-container {
    width: 100vw;
    background-color: #16267e;
  }

  .advert {
    display: block;
    width: 100%;
    color: white;
    font-size: 0.85vw;
    font-weight: 600;
    padding-top: 9px;
    padding-bottom: 11px;
    height: fit-content;
    text-align: center;
  }

  .ahd-logo {
    cursor: pointer;
    display: flex;
    margin-top: 24px;
    margin-left: 52px;
    margin-right: 8.5vw;
    height: 77px;
  }

  .vertical-menu-item-container {
    cursor: pointer;
    flex-direction: column;
    margin: 35px;
    background-color: white;
  }

  .vertical-menu-item-container-landscape {
    cursor: pointer;
    flex-direction: column;
    margin: 15px;
    background-color: white;
  }

  .menu-dialog {
    position: absolute;
    width: fit-content;
    height: 100%;
    margin-top: 0;
    background-color: white;
  }

  .menu-dialog-landscape {
    position: absolute;
    width: 30%;
    height: 100%;
    margin-top: 0;
    background-color: white;
  }

  .menu-vertical {
    font-size: 18px;
    font-weight: 600;
    color: rgb(1, 7, 74);
  }
  .menu-vertical:hover {
    text-decoration: underline;
  }

  .footer-text {
    display: flex;
    justify-content: center;
    font-size: 12px;
    font-weight: 300;
    color: rgb(1, 7, 74);
    background-color: white;
    padding-top: 10px;
    padding-bottom: 10px;
  }
  
  .menu-icon {
    cursor: pointer;
    color: rgb(1, 7, 74);
    height: 40px;
    padding-top: 2.5%;
  }

  .menu-icon-landscape {
    cursor: pointer;
    color: rgb(1, 7, 74);
    height: 40%;
    padding-top: 4%;
    padding-right: 2.5%;
  }

  .menu {
    font-size: 16px;
    font-weight: 600;
    color: rgb(1, 7, 74);
  }
}

@media screen and (max-width: 1650px) and (min-width: 1581px) {
  .header {
    display: flex;
    border: none;
    height: 117.5px;
    position: relative;
    width: 100%;
    place-content: space-between;
    align-content: space-between;
    justify-content: space-between;
    right: 28px;
  }

  .ad-container {
    width: 100vw;
    background-color: #16267e;
  }

  .advert {
    display: block;
    width: 100%;
    color: white;
    font-size: 0.85vw;
    font-weight: 600;
    padding-top: 9px;
    padding-bottom: 11px;
    height: fit-content;
    text-align: center;
  }

  .ahd-logo {
    cursor: pointer;
    display: flex;
    margin-top: 24px;
    margin-left: 52px;
    margin-right: 8.5vw;
    height: 77px;
  }

  .vertical-menu-item-container {
    cursor: pointer;
    flex-direction: column;
    margin: 35px;
    background-color: white;
  }

  .vertical-menu-item-container-landscape {
    cursor: pointer;
    flex-direction: column;
    margin: 15px;
    background-color: white;
  }

  .menu-dialog {
    position: absolute;
    width: fit-content;
    height: 100%;
    margin-top: 0;
    background-color: white;
  }

  .menu-dialog-landscape {
    position: absolute;
    width: 30%;
    height: 100%;
    margin-top: 0;
    background-color: white;
  }

  .menu-vertical {
    font-size: 18px;
    font-weight: 600;
    color: rgb(1, 7, 74);
  }
  .menu-vertical:hover {
    text-decoration: underline;
  }

  .footer-text {
    display: flex;
    justify-content: center;
    font-size: 12px;
    font-weight: 300;
    color: rgb(1, 7, 74);
    background-color: white;
    padding-top: 10px;
    padding-bottom: 10px;
  }
  
  .menu-icon {
    cursor: pointer;
    color: rgb(1, 7, 74);
    height: 40px;
    padding-top: 2.5%;
  }

  .menu-icon-landscape {
    cursor: pointer;
    color: rgb(1, 7, 74);
    height: 40%;
    padding-top: 4%;
    padding-right: 2.5%;
  }

  .menu {
    font-size: 14.5px;
    font-weight: 600;
    color: rgb(1, 7, 74);
  }
}

@media screen and (max-width: 1580px) and (min-width: 1486px) {
  .header {
    display: flex;
    border: none;
    height: 117.5px;
    position: relative;
    width: 100%;
    place-content: space-between;
    align-content: space-between;
    justify-content: space-between;
    right: 28px;
  }

  .ad-container {
    width: 100vw;
    background-color: #16267e;
  }

  .advert {
    display: block;
    width: 100%;
    color: white;
    font-size: 0.85vw;
    font-weight: 600;
    padding-top: 9px;
    padding-bottom: 11px;
    padding-right: 10px;
    height: fit-content;
    text-align: center;
  }

  .ahd-logo {
    cursor: pointer;
    display: flex;
    margin-top: 24px;
    margin-left: 52px;
    margin-right: 8.5vw;
    height: 77px;
  }

  .vertical-menu-item-container {
    cursor: pointer;
    flex-direction: column;
    margin: 35px;
    background-color: white;
  }

  .vertical-menu-item-container-landscape {
    cursor: pointer;
    flex-direction: column;
    margin: 15px;
    background-color: white;
  }

  .menu-dialog {
    position: absolute;
    width: fit-content;
    height: 100%;
    margin-top: 0;
    background-color: white;
  }

  .menu-dialog-landscape {
    position: absolute;
    width: 30%;
    height: 100%;
    margin-top: 0;
    background-color: white;
  }

  .menu-vertical {
    font-size: 18px;
    font-weight: 600;
    color: rgb(1, 7, 74);
  }
  .menu-vertical:hover {
    text-decoration: underline;
  }

  .footer-text {
    display: flex;
    justify-content: center;
    font-size: 12px;
    font-weight: 300;
    color: rgb(1, 7, 74);
    background-color: white;
    padding-top: 10px;
    padding-bottom: 10px;
  }
  
  .menu-icon {
    cursor: pointer;
    color: rgb(1, 7, 74);
    height: 40px;
    padding-top: 2.5%;
  }

  .menu-icon-landscape {
    cursor: pointer;
    color: rgb(1, 7, 74);
    height: 40%;
    padding-top: 4%;
    padding-right: 2.5%;
  }

  .menu {
    font-size: 12.5px;
    font-weight: 600;
    color: rgb(1, 7, 74);
  }
}

@media screen and (max-width: 1485px) and (min-width: 831px) {
  .header {
    display: flex;
    border: none;
    height: 117.5px;
    position: relative;
    width: 100%;
    place-content: space-between;
    align-content: space-between;
    justify-content: space-between;
    right: 28px;
  }

  .ad-container {
    width: 100vw;
    background-color: #16267e;
  }

  .advert {
    display: block;
    width: 100vw;
    color: white;
    font-size: 1.25vw;
    font-weight: 600;
    padding-top: 9px;
    padding-bottom: 12px;
    padding-left: 10px;
    background-color: #16267e;
    text-align: left;
  }

  .ahd-logo {
    cursor: pointer;
    display: flex;
    margin-top: 24px;
    margin-left: 52px;
    margin-right: 20vw;
    height: 65%;
  }

  .vertical-menu-item-container {
    cursor: pointer;
    flex-direction: column;
    margin: 35px;
    background-color: white;
  }

  .vertical-menu-item-container-landscape {
    cursor: pointer;
    flex-direction: column;
    margin: 15px;
    background-color: white;
  }

  .menu-dialog {
    position: absolute;
    width: fit-content;
    height: 100%;
    margin-top: 0;
    background-color: white;
  }

  .menu-dialog-landscape {
    position: absolute;
    width: 30%;
    height: 100%;
    margin-top: 0;
    background-color: white;
  }

  .menu-vertical {
    font-size: 18px;
    font-weight: 600;
    color: rgb(1, 7, 74);
  }
  .menu-vertical:hover {
    text-decoration: underline;
  }

  .footer-text {
    display: flex;
    justify-content: center;
    font-size: 12px;
    font-weight: 300;
    color: rgb(1, 7, 74);
    background-color: white;
    padding-top: 10px;
    padding-bottom: 10px;
  }
  
  .menu-icon {
    cursor: pointer;
    color: rgb(1, 7, 74);
    height: 40px;
    padding-top: 4vh;
  }

  .menu-icon-landscape {
    cursor: pointer;
    color: rgb(1, 7, 74);
    height: 40%;
    padding-top: 4%;
    padding-right: 2.5%;
  }

  .menu {
    font-size: 18px;
    font-weight: 600;
    color: rgb(1, 7, 74);
  }
}

@media screen and (max-width: 830px) {
  .header {
    display: flex;
    border: none;
    height: 18vh;
    position: relative;
    width: 100%;
    place-content: space-between;
    align-content: space-between;
    justify-content: space-between;
    right: 28px;
  }

  .ad-container {
    width: 100vw;
    background-color: #16267e;
  }

  .advert {
    display: block;
    width: 95vw;
    color: white;
    font-size: 1em;
    font-weight: 600;
    padding-top: 9px;
    padding-bottom: 12px;
    padding-left: 10px;
    padding-right: 10px;
    text-align: left;
  }

  .ahd-logo {
    cursor: pointer;
    display: flex;
    margin-top: 3vh;
    margin-left: 50px;
    margin-right: 20vw;
    height: 46%;
  }

  .vertical-menu-item-container {
    cursor: pointer;
    flex-direction: column;
    margin: 12%;
    background-color: white;
  }

  .vertical-menu-item-container-landscape {
    cursor: pointer;
    flex-direction: column;
    margin: 3.5%;
    background-color: white;
  }

  .menu-dialog {
    position: absolute;
    width: fit-content;
    height: 100%;
    margin-top: 0;
    background-color: white;
  }

  .menu-dialog-landscape {
    position: absolute;
    width: 50%;
    height: 100%;
    margin-top: 0;
    background-color: white;
  }

  .menu-vertical {
    font-size: 16px;
    padding-right: 10%;
    font-weight: 600;
    color: rgb(1, 7, 74);
  }
  .menu-vertical:hover {
    text-decoration: underline;
  }

  .footer-text {
    display: flex;
    justify-content: center;
    font-size: 12px;
    font-weight: 300;
    color: rgb(1, 7, 74);
    background-color: white;
    padding-top: 3%;
    padding-bottom: 3%;
  }
  
  .menu-icon {
    cursor: pointer;
    color: rgb(1, 7, 74);
    height: 30%;
    padding-top: 5vh;
  }

  .menu-icon-landscape {
    cursor: pointer;
    color: rgb(1, 7, 74);
    height: 45%;
    padding-top: 2.5%;
    padding-right: 2.5%;
  }

  .menu {
    font-size: 18px;
    font-weight: 600;
    color: rgb(1, 7, 74);
  }
}

.horizontal-menu-item-container {
  cursor: pointer;
  flex-direction: row;
  margin-left: 50px;
  margin-top: 77.5px;
}

.close-icon-container {
  position: absolute;
  right: 15px;
  top: 15px;
}

.close-icon {
  cursor: pointer;
  width: 20px;
  color: rgb(1, 7, 74);
}

.main-content {
  flex: 1;
  height: fit-content;
}

.footer {
  display: block;
  width: 100%;
  position: relative;
}