/* Testimonials.css */

/* Container for all testimonials */
.container {
  width: 100vw;
  overflow-y: hidden;
  padding: 20px;
}

/* Dark background style for testimonials */
.dark {
  padding: 20px;
  background-color: rgb(229, 230, 231);
  position: relative;
}

.dark:hover {
  background-color: rgb(229, 230, 231);
  filter: brightness(98%);
}

/* Medium background style for alternating testimonials */
.medium {
  padding: 20px;
  background-color: rgb(242, 242, 242);
  position: relative;
}

.medium:hover {
  background-color: rgb(242, 242, 242);
  filter: brightness(98%);
}

/* Text division styling */
.text-div {
  left: 0;
  right: 0;
  width: 100%;
  height: fit-content;
  position: relative;
  padding-bottom: 4px;
}

/* Title styling for testimonials (if needed) */
.title {
  font-size: 115%;
  font-weight: 700;
  color: rgb(1, 7, 74);
  display: inline-block;
}

/* Description styling for testimonial text */
.description {
  font-size: 105%;
  font-weight: 500;
  font-style: italic;
  color: rgb(1, 7, 74);
  display: inline-block;
}

/* Author and location styling */
.author {
  font-size: 95%;
  font-weight: 500;
  color: rgb(1, 7, 74);
  display: inline-block;
  text-align: right;
  margin-top: 10px;
}

/* Button styling for links (PDFs, Videos) */
.publication-button {
  background-color: transparent;
  border-radius: 10px;
  border-width: 1.5px;
  border-color: rgb(1, 7, 74);
  border-style: solid;
  width: fit-content;
  height: fit-content;
  margin-right: 1vw;
  margin-top: 10px;
  padding: 10px 15px;
  font-size: 95%;
  font-weight: 600;
  color: rgb(1, 7, 74);
  text-transform: none;
  cursor: pointer;
}

.publication-button:hover {
  background-color: rgb(1, 7, 74);
  color: white;
}

/* Button container styling */
.button-div {
  left: 0;
  width: 100%;
  display: flex;
  justify-content: flex-start;
}

/* Video testimonial container */
.video-container {
  text-align: center;
  margin-top: 15px;
}

/* Responsive design */
@media (max-width: 768px) {
  .container {
      padding: 10px;
  }

  .text-div {
      padding: 10px 15px;
  }

  .description {
      font-size: 100%;
  }

  .author {
      font-size: 90%;
  }

  .publication-button {
      font-size: 90%;
      padding: 8px 12px;
  }
}
