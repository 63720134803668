@media screen and (min-width: 1301px) {
    .contact-container {
       padding-right: 20vw;
       background-color: rgb(242, 242, 242);
    }

    .image-container {
       width: 87%;
       height: 900px;
       position: relative;
       overflow: hidden;
       left: 0px;
    }

    .image-container-landscape {
      width: 87%;
      height: 900px;
      position: relative;
      overflow: hidden;
      left: 0px;
   }

    .image {
       height: 100%;
    }

    .email-text {
       font-size: 18px;
       font-weight: bold;
       color: rgb(1, 7, 74);
       position: absolute;
       top: 230px;
       left: 73%;
       right: 5%;
    }
    .link:hover { 
       filter: brightness(94%);
    }

    .email-text-landscape {
      font-size: 18px;
      font-weight: bold;
      color: rgb(1, 7, 74);
      position: absolute;
      top: 230px;
      left: 73%;
      right: 5%;
   }
   .link:hover { 
      filter: brightness(94%);
   }

    a {
       color: rgb(1, 7, 74);
    }

    .participate-button {
       position: absolute;
       top: 330px;
       left: 73%;
       background-color: transparent;
       border-radius: 10px;
       border-width: 1.5px;
       border-color: rgb(1, 7, 74);
       border-style: solid;
       width: fit-content;
       height: fit-content;
    }

    .participate-button-landscape {
      position: absolute;
      top: 330px;
      left: 73%;
      background-color: transparent;
      border-radius: 10px;
      border-width: 1.5px;
      border-color: rgb(1, 7, 74);
      border-style: solid;
      width: fit-content;
      height: fit-content;
   }

    .address-text {
       font-size: 18px;
       font-weight: bold;
       color: rgb(1, 7, 74);
       position: absolute;
       top: 430px;
       left: 73%;
    }

    .address-text-landscape {
      font-size: 18px;
      font-weight: bold;
      color: rgb(1, 7, 74);
      position: absolute;
      top: 430px;
      left: 73%;
   }

    .google-maps {
       overflow: hidden;
    }

    .google-maps iframe {
       position: absolute;
       top: 600px;
       left: 73%;
       width: 22%;
       height: 300px;
    }

    .google-maps-landscape {
      overflow: hidden;
   }

   .google-maps-landscape iframe {
      position: absolute;
      top: 600px;
      left: 73%;
      width: 22%;
      height: 300px;
   }
}

@media screen and (max-width: 1300px) and (min-width: 831px) {
   .contact-container {
       height: 100vh;
       padding-right: 20vw;
       background-color: rgb(242, 242, 242);
    }

   .image-container {
       width: 100vw;
       position: relative;
       overflow: hidden;
       left: 0px;
       top: 850px;
    }

   .image-container-landscape {
      width: 0vw;
   }

   .image {
       width: 100%;
    }

   .email-text {
       font-size: 18px;
       font-weight: bold;
       color: rgb(1, 7, 74);
       position: absolute;
       top: 20vh;
       left: 5%;
       right: 5%;
    }
   .link:hover { 
       filter: brightness(94%);
    }

   .email-text-landscape {
      font-size: 18px;
      font-weight: bold;
      color: rgb(1, 7, 74);
      position: absolute;
      top: 25vh;
      left: 5%;
      right: 5%;
   }
  .link:hover { 
      filter: brightness(94%);
   }

   a {
       color: rgb(1, 7, 74);
    }

   .participate-button {
       position: absolute;
       top: 27vh;
       left: 5%;
       background-color: transparent;
       border-radius: 10px;
       border-width: 1.5px;
       border-color: rgb(1, 7, 74);
       border-style: solid;
       width: fit-content;
       height: fit-content;
    }

   .participate-button-landscape {
      position: absolute;
      top: 33vh;
      left: 5%;
      background-color: transparent;
      border-radius: 10px;
      border-width: 1.5px;
      border-color: rgb(1, 7, 74);
      border-style: solid;
      width: fit-content;
      height: fit-content;
   }

   .address-text {
       font-size: 18px;
       font-weight: bold;
       color: rgb(1, 7, 74);
       position: absolute;
       top: 36vh;
       left: 5%;
    }

   .address-text-landscape {
      font-size: 18px;
      font-weight: bold;
      color: rgb(1, 7, 74);
      position: absolute;
      top: 43vh;
      left: 5%;
   }

   .google-maps {
       overflow: hidden;
    }

   .google-maps iframe {
       position: absolute;
       top: 50vh;
       left: 5%;
       width: 75%;
       height: 45%;
    }

   .google-maps-landscape {
      overflow: hidden;
   }

   .google-maps-landscape iframe {
      position: absolute;
      top: 60vh;
      left: 5%;
      width: 55%;
      height: 50%;
   }
}

@media screen and (max-width: 830px) {
   .contact-container {
      height: 120vh;
      padding-right: 20vw;
      background-color: rgb(242, 242, 242);
   }

   .image-container {
      width: 100vw;
      position: relative;
      overflow: hidden;
      left: 0px;
      top: 75%;
   }

   .image-container-landscape {
      width: 0px;
      position: relative;
      overflow: hidden;
      left: 0px;
   }

   .image {
      width: 100%;
   }

   .email-text {
      font-size: 115%;
      font-weight: bold;
      color: rgb(1, 7, 74);
      position: absolute;
      top: 35vh;
      left: 5%;
      right: 5%;
   }
   .link:hover { 
      filter: brightness(94%);
   }

   a {
      color: rgb(1, 7, 74);
   }

   .email-text-landscape {
      font-size: 115%;
      font-weight: bold;
      color: rgb(1, 7, 74);
      position: absolute;
      top: 75%;
      left: 5%;
      right: 5%;
   }
   .link:hover { 
      filter: brightness(94%);
   }

   .participate-button {
      position: absolute;
      top: 45vh;
      left: 5%;
      background-color: transparent;
      border-radius: 10px;
      border-width: 1.5px;
      border-color: rgb(1, 7, 74);
      border-style: solid;
      width: fit-content;
      height: fit-content;
   }

   .participate-button-landscape {
      position: absolute;
      top: 88%;
      left: 5%;
      background-color: transparent;
      border-radius: 10px;
      border-width: 1.5px;
      border-color: rgb(1, 7, 74);
      border-style: solid;
      width: fit-content;
      height: fit-content;
   }

   .address-text {
      font-size: 115%;
      font-weight: bold;
      color: rgb(1, 7, 74);
      position: absolute;
      top: 53vh;
      left: 5%;
   }

   .address-text-landscape {
      font-size: 100%;
      font-weight: bold;
      color: rgb(1, 7, 74);
      position: absolute;
      top: 105%;
      left: 5%;
   }

   .google-maps {
      overflow: hidden;
   }

   .google-maps iframe {
      position: absolute;
      top: 70vh;
      left: 5%;
      width: 90%;
      height: 55%;
   }

   .google-maps-landscape {
      overflow: hidden;
   }

   .google-maps-landscape iframe {
      position: absolute;
      top: 135%;
      left: 5%;
      width: 60%;
      height: 60%;
   }
}
