@media screen and (min-width: 1480px) {
  .slideshow-container {
    width: 102vw;
    height: 100vh;
    overflow: hidden;
    display: block;
  }

  .slideshow {
    display: block;
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }

  .slideshow-narrow {
    display: block;
    position: relative;
    width: 100%;
    height: 92%;
    overflow: hidden;
  }

  .text1 {
    width: 48vw;
    height: fit-content;
    padding-top: 10px;
    padding-bottom: 15px;
    padding-left: 15px;
    padding-right: 60px;
    color: white;
    background-color: rgba(109, 147, 204, 0.8);
    font-weight: bold;
    font-size: 38px;
    position: absolute;
    text-align: justify;
    top: 60vh;
    right: 0px;
  }

  .text2 {
    width: 48vw;
    height: fit-content;
    padding-top: 10px;
    padding-bottom: 15px;
    padding-left: 15px;
    padding-right: 15px;
    color: white;
    background-color: rgba(109, 147, 204, 0.8);
    font-weight: bold;
    font-size: 38px;
    position: absolute;
    text-align: justify;
    top: 60vh;
    left: 0px
  }

  .text3 {
    width: 48vw;
    height: fit-content;
    padding-top: 10px;
    padding-bottom: 15px;
    padding-left: 15px;
    padding-right: 15px;
    color: white;
    background-color: rgba(109, 147, 204, 0.8);
    font-weight: bold;
    font-size: 38px;
    position: absolute;
    text-align: justify;
    top: 7vh;
    left: 0px;
  }

  .text4 {
    width: 48vw;
    height: fit-content;
    padding-top: 10px;
    padding-bottom: 15px;
    padding-left: 15px;
    padding-right: 60px;
    color: white;
    background-color: rgba(109, 147, 204, 0.8);
    font-weight: bold;
    font-size: 38px;
    position: absolute;
    text-align: justify;
    top: 50vh;
    right: 0px
  }
  .here:hover { 
    filter: brightness(90%);
  }
}

@media screen and (max-width: 1479px) and (min-width: 1100px) {
  .slideshow-container {
    width: 102vw;
    height: 150vh;
    overflow: hidden;
    display: block;
  }

  .slideshow {
    width: 100%;
    height: 120%;
    display: block;
    position: relative;
    overflow: hidden;
  }

  .slideshow-narrow {
    width: 100%;
    height: 92%;
    display: block;
    overflow: hidden;
    position: relative;
  }

  .text1 {
    width: 56vw;
    height: fit-content;
    padding-top: 10px;
    padding-bottom: 15px;
    padding-left: 15px;
    padding-right: 15px;
    color: white;
    background-color: rgba(109, 147, 204, 0.8);
    font-weight: bold;
    font-size: 150%;
    position: absolute;
    text-align: left;
    top: 0;
    left: 0px;
  }

  .text2 {
    width: 48vw;
    height: fit-content;
    padding-top: 10px;
    padding-bottom: 15px;
    padding-left: 15px;
    padding-right: 15px;
    color: white;
    background-color: rgba(109, 147, 204, 0.8);
    font-weight: bold;
    font-size: 150%;
    position: absolute;
    text-align: left;
    top: 0;
    left: 0px
  }

  .text3 {
    width: 48vw;
    height: fit-content;
    padding-top: 10px;
    padding-bottom: 15px;
    padding-left: 15px;
    padding-right: 15px;
    color: white;
    background-color: rgba(109, 147, 204, 0.8);
    font-weight: bold;
    font-size: 150%;
    position: absolute;
    text-align: left;
    top: 0;
    left: 0px;
  }

  .text4 {
    width: 48vw;
    height: fit-content;
    padding-top: 10px;
    padding-bottom: 15px;
    padding-left: 15px;
    padding-right: 15px;
    color: white;
    background-color: rgba(109, 147, 204, 0.8);
    font-weight: bold;
    font-size: 150%;
    position: absolute;
    text-align: left;
    top: 0;
    left: 0px
  }
  .here:hover { 
    filter: brightness(90%);
  }
}

@media screen and (max-width: 1099px) and (min-width: 751px) {
  .slideshow-container {
    width: 102vw;
    height: 150vh;
    overflow: hidden;
    display: block;
  }

  .slideshow {
    width: 100%;
    height: 120%;
    display: block;
    overflow: hidden;
    position: relative;
  }

  .slideshow-narrow {
    width: 100%;
    height: 92%;
    display: block;
    overflow: hidden;
    position: relative;
  }

  .text1 {
    width: 56vw;
    height: fit-content;
    padding-top: 10px;
    padding-bottom: 15px;
    padding-left: 15px;
    padding-right: 15px;
    color: white;
    background-color: rgba(109, 147, 204, 0.8);
    font-weight: bold;
    font-size: 150%;
    position: absolute;
    text-align: left;
    top: 0;
    left: 0px;
  }

  .text2 {
    width: 48vw;
    height: fit-content;
    padding-top: 10px;
    padding-bottom: 15px;
    padding-left: 15px;
    padding-right: 15px;
    color: white;
    background-color: rgba(109, 147, 204, 0.8);
    font-weight: bold;
    font-size: 150%;
    position: absolute;
    text-align: left;
    top: 0;
    left: 0px
  }

  .text3 {
    width: 48vw;
    height: fit-content;
    padding-top: 10px;
    padding-bottom: 15px;
    padding-left: 15px;
    padding-right: 15px;
    color: white;
    background-color: rgba(109, 147, 204, 0.8);
    font-weight: bold;
    font-size: 150%;
    position: absolute;
    text-align: left;
    top: 0;
    left: 0px;
  }

  .text4 {
    width: 48vw;
    height: fit-content;
    padding-top: 10px;
    padding-bottom: 15px;
    padding-left: 15px;
    padding-right: 15px;
    color: white;
    background-color: rgba(109, 147, 204, 0.8);
    font-weight: bold;
    font-size: 150%;
    position: absolute;
    text-align: left;
    top: 0;
    left: 0px
  }
  .here:hover { 
    filter: brightness(90%);
  }
}


@media screen and (max-width: 750px) and (min-width: 601px) {
  .slideshow-container {
    width: 102vw;
    height: 100vh;
    overflow: hidden;
    display: block;
  }

  .slideshow {
    width: 100%;
    height: 120%;
    display: block;
    overflow: hidden;
    position: relative;
  }

  .slideshow-narrow {
    width: 99.99vw;
    height: 92%;
    display: block;
    overflow: hidden;
    position: relative;
  }

  .text1 {
    width: 56vw;
    height: fit-content;
    padding-top: 10px;
    padding-bottom: 15px;
    padding-left: 15px;
    padding-right: 15px;
    color: white;
    background-color: rgba(109, 147, 204, 0.8);
    font-weight: bold;
    font-size: 150%;
    position: absolute;
    text-align: left;
    top: 0;
    left: 0px;
  }

  .text2 {
    width: 56vw;
    height: fit-content;
    padding-top: 10px;
    padding-bottom: 15px;
    padding-left: 15px;
    padding-right: 15px;
    color: white;
    background-color: rgba(109, 147, 204, 0.8);
    font-weight: bold;
    font-size: 150%;
    position: absolute;
    text-align: left;
    top: 0;
    left: 0px
  }

  .text3 {
    width: 56vw;
    height: fit-content;
    padding-top: 10px;
    padding-bottom: 15px;
    padding-left: 15px;
    padding-right: 15px;
    color: white;
    background-color: rgba(109, 147, 204, 0.8);
    font-weight: bold;
    font-size: 150%;
    position: absolute;
    text-align: left;
    top: 0;
    left: 0px;
  }

  .text4 {
    width: 56vw;
    height: fit-content;
    padding-top: 10px;
    padding-bottom: 15px;
    padding-left: 15px;
    padding-right: 15px;
    color: white;
    background-color: rgba(109, 147, 204, 0.8);
    font-weight: bold;
    font-size: 150%;
    position: absolute;
    text-align: left;
    top: 0;
    left: 0px
  }
  .here:hover { 
    filter: brightness(90%);
  }
}

@media screen and (max-width: 600px) {
  .slideshow-container {
    width: 102vw;
    height: 100vh;
    overflow: hidden;
    display: block;
  }

  .slideshow {
    width: 100%;
    height: 120%;
    display: block;
    overflow: hidden;
    position: relative;
  }

  .slideshow-narrow {
    width: 100%;
    height: 92%;
    display: block;
    overflow: hidden;
    position: relative;
  }

  .text1 {
    width: 56vw;
    height: fit-content;
    padding-top: 10px;
    padding-bottom: 15px;
    padding-left: 15px;
    padding-right: 15px;
    color: white;
    background-color: rgba(109, 147, 204, 0.8);
    font-weight: bold;
    font-size: 120%;
    position: absolute;
    text-align: left;
    top: 0;
    left: 0px;
  }

  .text2 {
    width: 56vw;
    height: fit-content;
    padding-top: 10px;
    padding-bottom: 15px;
    padding-left: 15px;
    padding-right: 15px;
    color: white;
    background-color: rgba(109, 147, 204, 0.8);
    font-weight: bold;
    font-size: 120%;
    position: absolute;
    text-align: left;
    top: 0;
    left: 0px
  }

  .text3 {
    width: 56vw;
    height: fit-content;
    padding-top: 10px;
    padding-bottom: 15px;
    padding-left: 15px;
    padding-right: 15px;
    color: white;
    background-color: rgba(109, 147, 204, 0.8);
    font-weight: bold;
    font-size: 120%;
    position: absolute;
    text-align: left;
    top: 0;
    left: 0px;
  }

  .text4 {
    width: 56vw;
    height: fit-content;
    padding-top: 10px;
    padding-bottom: 15px;
    padding-left: 15px;
    padding-right: 15px;
    color: white;
    background-color: rgba(109, 147, 204, 0.8);
    font-weight: bold;
    font-size: 120%;
    position: absolute;
    text-align: left;
    top: 0;
    left: 0px
  }
  .here:hover { 
    filter: brightness(90%);
  }
}

