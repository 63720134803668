@media screen and (min-width: 1300px) {
    .members-container {
        display: grid;
        grid-template-rows: repeat(3, 330px);
        grid-template-columns: repeat(4, 25%);
    }
}

@media screen and (max-width: 1299px) and (min-width: 660px) {
    .members-container {
        display: grid;
        grid-template-rows: repeat(6, 340px);
        grid-template-columns: repeat(2, 50%);
    }
}

@media screen and (max-width: 659px) {
    .members-container {
        display: grid;
        grid-template-rows: repeat(12, 330px);
        grid-template-columns: repeat(1, 100%);
    }
}

.dark-background {
    padding-top: 15px;
    padding-left: 20px;
    padding-right: 20px;
    background-color: rgb(229, 230, 231);
    position: relative;
}
.dark-background:hover {
    background-color: rgb(229, 230, 231);
    filter: brightness(92%);
}

.medium-background {
    padding-top: 15px;
    padding-left: 20px;
    padding-right: 20px;
    background-color: rgb(242, 242, 242);
    position: relative;
}
.medium-background:hover {
    background-color: rgb(242, 242, 242);
    filter: brightness(92%);
}

.light-background {
    padding-top: 15px;
    padding-left: 20px;
    padding-right: 20px;
    background-color: rgb(248, 247, 247);
    position: relative;
}
.light-background:hover {
    background-color: rgb(248, 247, 247);;
    filter: brightness(92%);
}

.text-container {
    left: 0;
    right: 0;
    width: 100%;
    height: fit-content;
    position: relative;
}

.name {
    font-size: 22px;
    font-weight: 700;
    color: rgb(1, 7, 74);
    display: 'inline-block';
}

.job-title {
    font-size: 16px;
    font-weight: 500;
    color: rgb(1, 7, 74);
    display: 'inline-block';
}

.profile-pic {
    height: 200px;
    right: 0px;
    bottom: 0px;
    position: absolute;
    overflow: hidden;
}

/* .email-button {
    height: fit-content;
    border-radius: 40px;
} */

.icon {
    cursor: pointer;
    width: 25px;
    margin-top: 40px;
    margin-right: 5px;
    flex: 1 0;
}
.icon:hover {
    filter: brightness(87.5%);
}
